const initNavbar = () => {

const changeCursorColor = document.querySelector('.cursor');

if (document.getElementById("toggle") !=null) {
  document.getElementById("toggle").addEventListener('change', function() {
    if (this.checked) {
      if (document.querySelector('.landing-container') !=null) {
        document.querySelector('.landing-container').classList.add('active');
      }

      if (document.querySelector('.nav-title-with-logo') !=null) {
        document.querySelector('.nav-title-with-logo').classList.add('active');
      }

      if (document.querySelector('.page-content-container') !=null) {
        document.querySelector('.page-content-container').classList.add('active');
      }

      if (document.querySelector('.form-box') !=null) {
        document.querySelector('.form-box').classList.add('active');
      }

      changeCursorColor.classList.add("active");

    } else {
      if (document.querySelector('.landing-container') !=null) {
        document.querySelector('.landing-container').classList.remove('active');
      }

      if (document.querySelector('.nav-title-with-logo') !=null) {
        document.querySelector('.nav-title-with-logo').classList.remove('active');
      }

      if (document.querySelector('.page-content-container') !=null) {
        document.querySelector('.page-content-container').classList.remove('active');
      }

      if (document.querySelector('.form-box') !=null) {
        document.querySelector('.form-box').classList.remove('active');
      }

      changeCursorColor.classList.remove("active");
      }
    });
  }
}

export { initNavbar };
