const initImageFocus = () => {

  const images = document.querySelectorAll('.gallery-image');

  images.forEach((input) => {
    input.addEventListener('click', (event) => {
      images.forEach((input) => {
        input.classList.remove('active');
      });
        input.classList.add('active');
    });
  })
}

export { initImageFocus };
